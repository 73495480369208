import ContentLoader from "react-content-loader";

export default function loaderContent() {
  return (
    <div>
      <ContentLoader
        speed={3}
        viewBox="0 0 600 270"
        backgroundColor="#E8E8E8"
        foregroundColor="#ecebeb"
      >
        {/* <circle cx="50" cy="20" r="13" />
        <rect x="55" y="13" rx="3" ry="3" width="7%" height="15" />
        <rect x="470" y="13" rx="3" ry="3" width="8%" height="15" />
        <circle cx="535 " cy="20" r="10" />
        <rect x="548" y="15" rx="3" ry="3" width="5%" height="10" />
        <rect x="2" y="40" rx="3" ry="3" width="24%" height="240" />*/}
        <rect x="160" y="50" rx="3" ry="3" width="10%" height="17" />
        <rect x="160" y="85" rx="3" ry="3" width="32%" height="40" />
        <rect x="385" y="85" rx="3" ry="3" width="32%" height="40" />
        <rect x="160" y="135" rx="3" ry="3" width="32%" height="40" />
        <rect x="385" y="135" rx="3" ry="3" width="32%" height="40" />
        <rect x="160" y="185" rx="3" ry="3" width="6%" height="15" />
        <rect x="540" y="185" rx="3" ry="3" width="6%" height="15" />
        <rect x="160" y="205" rx="3" ry="3" width="69.5%" height="12" />
        <rect x="160" y="223" rx="3" ry="3" width="69.5%" height="12" />
        <rect x="160" y="240" rx="3" ry="3" width="69.5%" height="12" />
        <rect x="160" y="257" rx="3" ry="3" width="69.5%" height="12" />
      </ContentLoader>
    </div>
  );
}
